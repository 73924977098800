#projectHeading{
    text-align: center;
    margin: 80px auto 0px;
    font-family: "Poppins", sans-serif;
    font-size: 2rem;
    text-decoration: underline 3px rgb(167, 165, 165);
}

.oneProject{
    margin: 40px auto;
    padding: 30px 30px;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 60vw;
    display: flex;
}

.number{
    border-radius: 30px;

    background: #262626;
    box-shadow: -24px 24px 54px #1a1a1a,
                24px -24px 54px #323232;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22vh;
    min-width: 22vh;
    width: 22vh;
    font-family: 'Monument Extended', sans-serif;
    font-size: 1.8rem;
    position: relative;
}

.project-content-right{
    margin-left: 100px;
    text-align: left;
    width: 100%;
}

.project-content-left{
    text-align: right;
    margin-right: 100px;
    width: 100%;
}

.project-content-right h3, .project-content-left h3{
    font-family: 'Poppins', sans-serif;
    font-size: 1.8rem;
    padding: 0px 30px;
    background-color: white;
    color: rgb(36, 36, 36);
    
}

.project-content-right p, .project-content-left p{
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    font-weight: 300;
    position: relative;
    
}
.languageList
{
    display: flex;
    flex-direction: row;
}
.language
{
    margin: 10px 10px 15px;
    margin-top: 15px !important;
    padding: 2px 10px;
    background-color: rgba(95, 253, 253, 0.459);
    border: 1px solid rgb(95, 253, 253);
    border-radius: 7px;
    display: flex;
    width: fit-content;
    
}

.project-content-left .languageList
{
    flex-direction: row-reverse;
    margin-right: -10px;
}

.project-content-right .languageList
{
    margin-left: -10px;

}

.url
{
    padding: 10px 0;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.project-content-left .url
{
    flex-direction: row-reverse;
}

.url a
{
    font-size: 1rem;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;
    
}
.url a:hover
{
    text-decoration: underline;
   
}

.icon
{
    margin: 0 8px;
}

.project-img
{
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}