.header {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Subheading{
    margin: 0;
}
.logo {
  height: 70px;
  border-radius: 70px;
  margin-right: 20px;
  /* box-shadow: -4px -4px 20px 0px  rgba(255,249,34),
                4px 4px 20px 0px rgba(255,0,128); */
}

.header h1 {
  font-size: 4rem;
  /* text-transform: uppercase; */
  letter-spacing: -5px;
  margin: 0 0px;
}

.header h3 {
  font-family: "Monument Extended", sans-serif;
  margin: 0 0 30px 20px;
}
.underline {
  margin: auto;
  height: 4px;
  width: 100%;
  background: linear-gradient(0.25turn, rgba(255, 249, 34), rgba(255, 0, 128));
}

span.pressG {
  margin-top: 20px;
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
  font-size: 1rem;
  display: block;
}

code {
  padding: 3px 6px;
  background-color: rgba(255, 255, 255, 0.283);
  border-radius: 3px;
  /* backdrop-filter: blur(2px); */
  color: white;
}

@media (max-width: 420px) {
  .header h1 {
    font-size: 2.5rem;
  }

  .underline {
    width: 280px;
  }
}
