.github2{
    position: sticky;
    bottom: 30px;
    right: 30px;
    text-align: right;
}

.github2 img{
    position: relative;
    right: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    /* animation: bounce-2 1s ease 0s infinite; */
    transition: 0.3s;
} 

.github2 img:hover
{
    box-shadow: -2px -2px 10px 0px  rgba(255,249,34),
                2px 2px 10px 0px rgba(255,0,128);
    transform: scale(1.2);
    transition: 0.3s;
}

@media (max-width:830px) {

    .github2
    {
        position: relative;
        bottom: 20px;
        right: 0px;
        text-align: center;
    }
    .github2 img{
        position: relative;
        right: 0px;
        bottom: 20px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: transparent;
        /* animation: bounce-2 1s ease 0s infinite; */
        transition: 0.3s;
    } 
}