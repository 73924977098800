.topics
{
    width: fit-content;
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-items: flex-end;
}

.topics-right
{
   margin-right: 0;
   margin-left: auto;
   
}

.topic
{
    font-size: 0.7rem;
    font-weight: 400;
    margin: 5px 5px;
    padding: 5px 10px;
    border-radius: 1rem;
    transition: 0.3s;

    /* background-color: rgba(225, 0, 255, 0.287);
    border: 1px solid rgb(212, 148, 248); */

    background-color: rgba(255, 0, 0, 0.301);
    border: 1px solid red;
}

.topic:hover
{
    transform: scale(0.9);
    transition: 0.3s;
    cursor: pointer;
    
}