.construction{
    margin: 80px auto 50px;
    padding: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}


.construction img{
    width: 35vw;
    min-width: 480px;
    padding: 20px;
    animation: bounce 3s ease 0s infinite normal none;
}



.construction p{
    font-family: 'Monument Extended', sans-serif;
    font-weight: lighter;
    word-spacing: 10%;
    letter-spacing: 1px;
    padding: 20px;
    font-size: 1.5rem;
    width: 30%;
    text-align: left;
}

.construction p span{
    font-size: 0.9rem;
}


@media (max-width:830px) {
    .construction{
        flex-direction: column;
        margin-bottom: 10px;
        margin-top: 50px;
    }

    .construction p{
        width: 70vw;
        text-align: center;
    }

    .construction img {
        width: 90%;
        padding: 0;
        min-width: 0;
        max-width: 520px;
    }

    .pressG{
        visibility: hidden;
    }
    
}

@media (max-width:512px){

    .construction p{
        font-size: 1.2rem;
    }

    .construction p span{
        font-size: 0.8rem;
    }

}

/* @media (max-width:420px){

    .header h1 {
        font-size: 2.5rem;
    }

    .underline {
        width: 280px;
    }
    
} */

/* Animations */

@keyframes bounce {
    
    0%{
        transform: translate(0,-10px);
    }
    50%{
        transform: translate(0,10px);
    }
    100%{
        transform: translate(0,-10px);
    }
}